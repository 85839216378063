import "./main.css";
import { Elm } from "./Main.elm";
import * as serviceWorker from "./serviceWorker";

const app = Elm.Main.init({
	node: document.getElementById("root"),
	flags: {
		endpoint: process.env.ELM_APP_PROD_URL,
		api_key: process.env.ELM_APP_API_KEY,
		gesloten_opleidingen: process.env.ELM_APP_OPLEIDING_SLUITEN.split(","),
		gesloten_afdelingen: process.env.ELM_APP_AFDELING_SLUITEN.split(","),
		gesloten_hoofdvakken: process.env.ELM_APP_HOOFDVAK_SLUITEN.split(","),
	},
});
//set to ELM_APP_DEV_URL for dev and ELM_APP_PROD_URL for prod
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA`
serviceWorker.unregister();

// ports:
app.ports.sendNext.subscribe(function (section) {
	const requiredFields = document.querySelectorAll(
		"input[required], select[required], textarea[required]",
	);
	const patternFields = document.querySelectorAll("input[pattern]");
	let next = true;
	const notification = { field: "", msg: "" };

	switch (section) {
		case "persoonsgegevens": {
			const emailfields = document.querySelectorAll("input[type=email]");
			const gb = document.querySelector("input[name=geboortedatum]");

			if (!gb.validity.valueMissing) {
				const today = new Date();
				const chosenDate = new Date(gb.value);
				const mindate = new Date(
					today.getFullYear() - 8,
					today.getMonth(),
					today.getDay(),
				);
				if (chosenDate > mindate) {
					next = false;
					notification.msg = "minleeftijd";
					app.ports.validationReceived.send(notification);
					gb.focus();
					return;
				}
				// Date format validation (should be jjjj-mm-dd)
				const parsedDate = new Date(Date.parse(gb.value));
				if (parsedDate === "Invalid Date" || parsedDate.getFullYear() < 1000) {
					next = false;
					notification.msg = "invaliddate";
					app.ports.validationReceived.send(notification);
					gb.focus();
					return;
				}
			}

			if (!emailfields[0].validity.valueMissing) {
				if (!emailfields[0].validity.valid) {
					next = false;
					notification.msg = "emailcheck";
					app.ports.validationReceived.send(notification);
					emailfields[0].focus();
					return;
				} else {
					if (emailfields[0].value === emailfields[1].value) {
						next = true;
					} else {
						notification.msg = "emailgelijk";
						app.ports.validationReceived.send(notification);
						emailfields[1].focus();
						next = false;
						return;
					}
				}
			}

			for (const pf of patternFields) {
				if (!pf.validity.valid && !pf.validity.valueMissing) {
					pf.focus();
					notification.msg = "patternLetters";
					notification.field = pf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}

			for (const rf of requiredFields) {
				if (rf.validity.valueMissing) {
					rf.focus();
					notification.msg = "";
					notification.field = rf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}

			if (next) {
				app.ports.nextReceived.send("Studierichting");
			}
			break;
		}
		case "studierichting": {
			const baDatum = document.querySelector("input[name=ba_diploma_datum]");

			if (baDatum) {
				const parsedDate = new Date(Date.parse(baDatum.value));
				const today = new Date();
				if (
					parsedDate === "Invalid Date" ||
					parsedDate.getFullYear() < 1000 ||
					parsedDate.getFullYear() > today.getFullYear()
				) {
					next = false;
					notification.msg = "invaliddate";
					app.ports.validationReceived.send(notification);
					baDatum.focus();
					return;
				}
			}

			for (const rf of requiredFields) {
				if (rf.validity.valueMissing) {
					rf.focus();
					notification.field = rf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			if (next) {
				app.ports.nextReceived.send("Studieverleden");
			}
			break;
		}
		case "studieverleden":
			for (const rf of requiredFields) {
				if (rf.validity.valueMissing) {
					rf.focus();
					notification.field = rf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			if (next) {
				app.ports.nextReceived.send("Mastermotivatie");
			}
			break;
		case "studieverledenba":
			for (const rf of requiredFields) {
				if (rf.validity.valueMissing) {
					rf.focus();
					notification.field = rf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			if (next) {
				app.ports.nextReceived.send("Aanmeldgegevens");
			}
			break;
		case "studieverledennascholing":
			for (const rf of requiredFields) {
				if (rf.validity.valueMissing) {
					rf.focus();
					notification.field = rf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			if (next) {
				app.ports.nextReceived.send("Uploads");
			}
			break;
		case "mastermotivatie":
			for (const rf of requiredFields) {
				if (rf.validity.valueMissing) {
					rf.focus();
					notification.field = rf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			if (next) {
				app.ports.nextReceived.send("Socialmedia");
			}
			break;
		case "aanmeldgegevens":
			for (const rf of requiredFields) {
				if (rf.validity.valueMissing) {
					rf.focus();
					notification.field = rf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			if (next) {
				app.ports.nextReceived.send("Socialmedia");
			}
			break;
		case "aanmeldgegevenstrack":
			for (const rf of requiredFields) {
				if (rf.validity.valueMissing) {
					rf.focus();
					notification.field = rf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			if (next) {
				app.ports.nextReceived.send("Uploads");
			}
			break;
		case "aanmeldgegevensma":
			for (const rf of requiredFields) {
				if (rf.validity.valueMissing) {
					rf.focus();
					notification.field = rf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			if (next) {
				app.ports.nextReceived.send("Mastermotivatie");
			}
			break;
		case "socialmedia": {
			const links = document.querySelectorAll("input[name^=link]");
			const videos = document.querySelectorAll("input[name^=video]");
			for (const rf of requiredFields) {
				if (rf.validity.valueMissing) {
					rf.focus();
					notification.field = rf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			for (const link of links) {
				if (link.validity.patternMismatch) {
					link.focus();
					notification.field = "linkcheck";
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
				if (link.value.length > 250) {
					notification.field = "maxlinkCharserror";
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			for (const video of videos) {
				if (video.validity.patternMismatch) {
					video.focus();
					notification.field = "linkcheck";
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
				if (video.value.length > 250) {
					notification.field = "maxlinkCharserror";
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			if (next) {
				app.ports.nextReceived.send("Uploads");
			}
			break;
		}
		case "uploads":
			for (const rf of requiredFields) {
				if (rf.validity.valueMissing) {
					rf.focus();
					notification.field = rf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			if (next) {
				app.ports.nextReceived.send("Opmerkingen");
			}
			break;
		case "opmerkingen":
			for (const pf of patternFields) {
				if (!pf.validity.valid && !pf.validity.valueMissing) {
					pf.focus();
					notification.msg = "patternLetters";
					notification.field = pf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}

			for (const rf of requiredFields) {
				if (rf.validity.valueMissing) {
					rf.focus();
					notification.field = rf.name;
					app.ports.validationReceived.send(notification);
					next = false;
					break;
				}
			}
			if (next) {
				app.ports.nextReceived.send("Controle en versturen");
			}
			break;
		default:
			break;
	}
});

// storage
app.ports.storeLocal.subscribe(function (obj) {
	localStorage.setItem("cvaAanmelden2021", JSON.stringify(obj));
});

app.ports.requestLocal.subscribe(function () {
	const a = localStorage.getItem("cvaAanmelden2021");
	app.ports.sendLocalStore.send(JSON.parse(a));
});

app.ports.checkLocal.subscribe(function () {
	const a = localStorage.getItem("cvaAanmelden2021");
	app.ports.hasLocal.send(a != null);
});

app.ports.removeLocal.subscribe(function () {
	localStorage.removeItem("cvaAanmelden2021");
});

app.ports.removeUpload.subscribe(function (name) {
	const i = document.getElementsByName(name);
	i[0].value = "";
});

app.ports.renameUpload.subscribe(function (fileobj) {
	const field = fileobj?.field;
	const ext = fileobj?.ext;
	const fileinput = document.getElementsByName(field)[0];
	const file = fileinput.files[0];
	const renamedFile = new File([file], `${field}.${ext}`, { type: file.type });
	const dT = new DataTransfer();
	dT.items.add(renamedFile);
	fileinput.files = dT.files;
	fileinput.dispatchEvent(new Event("change"));
});
